<template>
  <div class="chess-side-modal">
    <div class="side-modal-wrap">
      <div class="top-row">
        <h2 class="title">Создать</h2>
        <div class="close" style="top: 13px" @click.self="closeModal">
          &#10005;
        </div>
      </div>

      <div class="tab-row" style="margin: 24px 0 42px">
        <div
          v-for="item in types"
          :key="item.id"
          class="tab-item"
          :class="{
            active: formData.type === item.id,
          }"
          @click="$set(formData, 'type', item.id)"
        >
          {{ item.title }}
        </div>
      </div>
      <v-form class="create-news" ref="newsInput" @submit.prevent="">
        <v-text-field
          v-model="formData.title"
          :rules="newsHeaderRules"
          label="Заголовок"
          style="margin-bottom: 12px"
          color="#5b6cff"
          outlined
          required
        ></v-text-field>
        <v-textarea
          v-model="formData.body"
          :rules="newsDescriptionRules"
          label="Описание"
          color="#5b6cff"
          auto-grow
          outlined
          required
        ></v-textarea>
        <AddPhoto
          @push="pushImage"
          @delete="deleteImage"
          :images="attachments"
        />
        <div class="down">
          <div class="switch-box">
            Отправить уведомление жителям
            <label class="switch">
              <input type="checkbox" v-model="formData.send_push" />
              <span class="slider"></span>
            </label>
          </div>
          <v-btn type="button" class="create-news-button" @click="submitNews"
            >Создать</v-btn
          >
        </div>
      </v-form>
    </div>
  </div>
</template>
<script>
import {
  newsAddApi,
  newsTypeApi,
  newsIdApi,
  newsAddPhotoApi,
} from "@/api/news";

export default {
  data() {
    return {
      types: [],
      uploadedPhoto: [],
      formData: {
        title: "",
        body: "",
        send_push: false,
      },
      attachments: [],
      formDataImages: [],
      newsHeaderRules: [
        (v) => !!v || "Заголовок обязателен",
        (v) =>
          (v && v.length >= 7) || "Заголовок должен быть не менее 7 символов",
      ],
      newsDescriptionRules: [
        (v) => !!v || "Необходимо заполнить описание",
        (v) =>
          (v && v.length >= 15) || "Описание должно быть не менее 15 символов",
      ],
    };
  },
  components: {
    AddPhoto: () => import("@/components/ui/addPhoto"),
  },
  watch: {
    $route: {
      immediate: true,
      handler(to) {
        /** prefill type */
        const { type } = JSON.parse(to.query.newsFilter || "{}");
        this.$set(this.formData, "type", type || this.types[0]?.id);
      },
    },
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.getNewsType();
    },
    async getNewsType() {
      const res = await newsTypeApi();
      this.types = res.data;

      /** set default type */
      const { type } = JSON.parse(this.$route.query.newsFilter || "{}");
      this.$set(this.formData, "type", type || this.types[0]?.id);
    },

    async submitNews() {
      if (this.$refs.newsInput.validate() === true) {
        this.appendNews();
      } else {
        alert("Заполните форму правильно!");
      }
    },

    closeModal() {
      this.formdata = {};
      this.$emit("close");
    },

    pushImage(file) {
      this.attachments.push(file.file);
    },

    deleteImage(i) {
      this.$delete(this.attachments, i);
    },

    async appendNews() {
      this.formDataImages = [];
      console.log("photos", this.attachments);
      const res = await newsAddApi(this.formData);
      Promise.all(
        this.attachments.map((item) => {
          const imageFormData = new FormData();
          imageFormData.append("photo", item);
          imageFormData.append("news_id", res.data.id);
          return newsAddPhotoApi(imageFormData)
            .then((response) => {
              console.log("image request");
              console.log(response);
              this.formDataImages.push(response?.data?.id);
              console.log("image", this.formDataImages);
            })
            .catch((error) => console.log(error));
        })
      ).then(() => {
        this.$refs.newsInput.reset();
        this.$emit("update", this.formData.type);
        this.formData = {};
        this.formDataImages = [];
        this.attachments = [];
      });
    },
  },
};
</script>

<style>
</style>